<script lang="ts" setup>
defineEmits<{
  (e: "close"): void;
}>();

withDefaults(
  defineProps<{
    type: "slider" | "stacked";
    size?: "default" | "small";
    products: any;
    showProductSizes?: boolean;
    isProductListing?: boolean;
    loading?: "lazy" | "eager" | undefined;
  }>(),
  {
    size: "default",
    showProductSizes: false,
    isProductListing: false,
    loading: "lazy",
  },
);
const { isLoading } = useFacettedSearchHelpers();

const isLocked = ref(false);

function lockedStatus(swiper: Swiper) {
  isLocked.value = swiper.isLocked;
}

const spaceBetween = 12;
const breakpoints = {
  480: {
    slidesPerView: 1.75,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 24,
  },
  768: {
    slidesPerView: 2.5,
  },
  1024: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 3.5,
  },
  1536: {
    slidesPerView: 4,
    spaceBetween: 32,
  },
  // 1700: {
  //   slidesPerView: 5,
  //   spaceBetween: 40,
  // },
  // 1920: {
  //   slidesPerView: 6,
  // },
};

const uid = useState('uid', () => getCurrentInstance()?.uid);

const inlinestyleforslider = Object.entries(breakpoints)
  .map((breakpoint) => {
    const [key, value] = breakpoint;
    const gap = value.spaceBetween ?? spaceBetween;
    const width = value.slidesPerView ?? 0;
    const slideGap = width > 0 ? gap : 0

    return `@media (min-width: ${key}px) {
      #slider-${uid.value}:not(.swiper-initialized) .swiper-wrapper {gap: ${gap}px;}
      #slider-${uid.value}:not(.swiper-initialized) .swiper-slide {width: calc(100% / ${width} - ${slideGap}px);}
    }`;
  })
  .join("");

useHead({
  style: [
    inlinestyleforslider
  ],
});
</script>

<template>
  <div v-if="products.length" class="@container/productlisting">
    <div
      v-if="type === 'stacked'"
      :class="{
        'grid grid-gap-2 @md/productlisting:grid-gap-6 @4xl/productlisting:grid-gap-8 @7xl/productlisting:grid-gap-10': true,
        'grid-cols-2 @2xl/productlisting:grid-cols-3 @7xl/productlisting:grid-cols-4  ': size === 'default',
        'grid-cols-2 @xl/productlisting:grid-cols-3 @2xl/productlisting:grid-cols-4': size === 'small',
      }"
      class="relative"
    >
      <div
        v-if="isLoading"
        class="absolute inset-0 z-3000 flex m-[-25px] items-start justify-center bg-maas-background-default/60 backdrop-blur-lg"
      >
        <span class="m-[30vh] spinner"></span>
      </div>

      <template v-for="(product, index) in products" :key="product.id">
        <!--        <RenderCacheable :cache-key="`${product.id}`" :cache-tags="getProductCacheKeys(product)" :max-age="3600">-->
        <ProductCard :is-product-listing="isProductListing" :product="product" :show-size="showProductSizes" @link-clicked="$emit('close')" :loading="loading === 'eager' && index < 4 ? 'eager' : 'lazy'" />
        <!--        </RenderCacheable>-->
      </template>
    </div>

    <template v-if="type === 'slider'">
      <nuxt-error-boundary @error="() => {}">
        <Swiper
          :id="`slider-${uid}`"
          :breakpoints="breakpoints"
          :free-mode="true"
          :grab-cursor="true"
          :modules="[SwiperFreeMode]"
          :slides-per-view="1.25"
          :space-between="spaceBetween"
          breakpoints-base="container"
          @lock="lockedStatus"
          @unlock="lockedStatus"
        >
          <template v-for="(product, index) in products" :key="product.id">
            <SwiperSlide :product="product">
              <!--            <RenderCacheable :cache-key="`${product.id}`" :cache-tags="getProductCacheKeys(product)" :max-age="3600">-->
              <ProductCard :is-product-listing="isProductListing" :product="product" @link-clicked="$emit('close')" :loading="loading === 'eager' && index < 4 ? 'eager' : 'lazy'" />
              <!--            </RenderCacheable>-->
            </SwiperSlide>
          </template>
        </Swiper>
      </nuxt-error-boundary>

      <img
        v-if="!isLocked"
        alt="Swipe"
        class="mx-auto mt-4 w-10 lg:mb-4 lg:mt-8"
        height="36"
        loading="lazy"
        src="@/assets/image/icon-swipe.svg"
        width="40"
      />
    </template>

    <div v-if="$slots.default" class="mt-10">
      <slot />
    </div>
  </div>
</template>
